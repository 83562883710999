import styled from "@emotion/styled";

export const Wrapper = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: myFirstFontReg;
    background-color: #f0efed;
    `;
    
export const OrderedList = styled.ol`
    padding: 0px 20px 20px 20px;
    width: 80%;
`;

export const TextBox = styled.h1`
    text-align: center;
`;
